@keyframes moveThroughLine {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 20px);
  }
}

.animate-moveThroughLine {
  animation: moveThroughLine 8s linear infinite;
}
