/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

@layer base {
  html {
    font-family: "Poppins", sans-serif;
  }
  * {
    scroll-behavior: smooth;
  }
  .icon {
    filter: brightness(0.7);
    transition: filter 0.5s ease-in-out;
  }
  .icon.hovered {
    filter: brightness(1);
    animation: iconhover 1s linear normal;
  }

  @keyframes iconhover {
    0% {
      scale: 1;
    }
    50% {
      scale: 1.3;
    }
    100% {
      scale: 1;
    }
  }
}
